<script setup>
import PrimaryButton from "@/Components/PrimaryButton.vue";
import SecondaryButton from "@/Components/SecondaryButton.vue";
import PublicLayout from "@/Layouts/PublicLayout.vue";
import DlgCasoBuscar from "@/Pages/Publico/DlgCasoBuscar.vue";
import {ref} from "vue";
import FootFC from "@/Components/FootFC.vue";


const dlgBuscar = ref(null);

const buscar = () => {
    dlgBuscar.value.open({}
        , (res) => {
            Object.keys(res.piezas).forEach(key => piezas[key] = res.piezas[key]);
        });

}


</script>

<template>
    <PublicLayout title="Nuestro Compromiso">

        <DlgCasoBuscar ref="dlgBuscar"/>

        <div class="py-2 sm:py-4">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div class="bg-white overflow-hidden shadow-xl sm:rounded-lg font-body text-md">
                    <div class="mx-4 sm:mx-6 lg:mx-14 my-6 sm:my-12">
                        <h1 class="font-head font-bold text-xl uppercase leading-tight text-red-900 mb-6">
                            Nuestro compromiso
                        </h1>
                        <p class="mb-4">
                            En la Fundación Carolina nos posicionamos como uno de los primeros actores en todos aquellos
                            sectores en los que concurrimos, comprometiéndonos con el progreso económico y social de los
                            países donde estamos presentes. Hemos mantenido a lo largo de toda nuestra historia un
                            compromiso empresarial con los diferentes grupos de interés, basado en principios éticos que
                            forman parte de nuestra cultura corporativa y que van más allá del estricto cumplimiento de
                            la Ley.
                        </p>
                        <p class="mb-4">
                            Nuestro Código de Conducta plasma los valores esenciales de la Fundación Carolina. No
                            obstante, y puesto que operamos en diferentes jurisdicciones, prevalecerán sobre las
                            disposiciones del Código de Conducta aquellas normas locales que sean más estrictas. Sin
                            embargo, no aplicaremos costumbres locales cuando las contravengan.
                        </p>
                        <p class="mb-4">
                            Asimismo, se anima a todos los miembros de la organización, así como a terceras partes
                            externas a la organización a nivel global, a que utilicen el Canal Ético para presentar
                            información relacionada con posibles infracciones a los compromisos y principios básicos
                            establecidos en nuestra Política de Derechos Humanos.
                        </p>
                        <p class="mb-4">
                            Asimismo, se anima a todos los miembros de la organización, así como a terceras partes
                            externas a la organización a nivel global, a que utilicen el Canal Ético para presentar
                            información relacionada con posibles infracciones a los compromisos y principios básicos
                            establecidos en nuestra Política de Derechos Humanos.
                        </p>
                    </div>

                    <div class="m-10 flex flex-row flex-wrap">
                        <div class="md:basis-1/2 md:text-right md:pr-6 basis-full text-center mb-4">
                            <primary-button class="h-12 w-60" onclick="window.location='/caso';">
                                Crear nueva comunicación
                            </primary-button>
                        </div>
                        <div class="md:basis-1/2 md:text-left md:pl-6 basis-full text-center mb-4">
                            <secondary-button class="h-12 w-60" @click="buscar();">
                                Seguimiento de comunicación existente
                            </secondary-button>
                        </div>
                    </div>

                    <FootFC class="mx-10 my-6"/>

                </div>
            </div>
        </div>
    </PublicLayout>
</template>
